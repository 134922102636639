import { Injectable } from "@angular/core";
import { APIConstant, Images } from "../constant";
import { Student, FeedBackRequest, Birthday, StudentField, StudentDropOutPaginate, StudentStrength, StudentContact, Exam, StudentMark } from 'models';
import { Observable, of } from "rxjs";
import { APIPagedResponse } from "models";
import { CRUDService, BaseService, CommonUtility, DateUtility } from "projects/common/src/public-api";
import { catchError, mergeMap } from 'rxjs/operators';

@Injectable({
    providedIn: "root",
})
export class StudentService extends CRUDService<Student> {
    create: any;

    constructor(protected baseService: BaseService) {
        super(baseService, APIConstant.student);
    }

    allStudent(params: any): Observable<APIPagedResponse<Student>> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.allStudent}?${query}`);
    }


    search(seach: string): Observable<Student[]> {
        const params = CommonUtility.convertObjectToParams({
            searchString: seach,
        });
        return this.baseService.get<Student[]>(`${APIConstant.searchStudent}?${params}`);
    }

    filter(data: any): Observable<Student[]> {
        const params = CommonUtility.convertObjectToParams(data);
        return this.baseService.get<Student[]>(`${APIConstant.searchStudent}?${params}`);
    }

    basicInfo(studentProfileId: number): Observable<Student> {
        return this.baseService.get<Student>(`${APIConstant.studentBasicInfo}?studentProfileId=${studentProfileId}`);
    }
    checkRollNumber(classroomId: number, rollNumber: string, isEditMode?: boolean): Observable<boolean> {
        return this.baseService.get<boolean>(`${APIConstant.checkRollnumber}?classroomId=${classroomId}&newRollNumber=${rollNumber}`);
    }
    
    // get relations
    getRelations(): Observable<Student>{
        return this.baseService.get<Student>(`${APIConstant.getRelations}`)
    }

    basicPostInfo(searchStudentProfileId: number) {
        // const requestBody = {
        //     studentSearchId: {
        //       searchStudentProfileId: searchStudentProfileId
        //     }
        //   };
        return this.baseService.post(`${APIConstant.postInfo}?searchStudentProfileId=${searchStudentProfileId}`, null)
    }

    getFeedback(studentProfileId: number, classroomId: number): Observable<APIPagedResponse<FeedBackRequest>> {
        let params = null;
        if (classroomId) {
            params = CommonUtility.convertObjectToParams({ studentProfileId: studentProfileId, classroomId: classroomId });
        } else {
            params = CommonUtility.convertObjectToParams({ studentProfileId });
        }
        return this.baseService.get(`${APIConstant.studentFeedback}?${params}`);
    }

    deleteFeedback(feedbackId: number): Observable<APIPagedResponse<boolean>> {
        return this.baseService.delete(`${APIConstant.studentFeedback}${feedbackId}`);
    }

    addFeedback(feedbackRequestData: FeedBackRequest): Observable<any> {
        return this.baseService.post(`${APIConstant.studentFeedback}`, feedbackRequestData);
    }

    updateLeaveRequest(feedbackId: number, feedbackRequestData: FeedBackRequest): Observable<any> {
        return this.baseService.put(`${APIConstant.studentFeedback}`, feedbackRequestData);
    }

    getImage(studentProfileId: number): Observable<string> {
        return this.baseService.getWithResponseType<string>(`${APIConstant.student}image/${studentProfileId}`, "text").pipe(
            catchError(() => of(Images.user))
        );
    }

    uploadImage(studentProfileId: number, file: File): Observable<any> {
        const queryParams = CommonUtility.convertObjectToParams({
            contentType: file.type
        })
        return this.baseService.put(`${APIConstant.student}${studentProfileId}/image?${queryParams}`, null).pipe(
            mergeMap((response: any) => {
                return this.baseService.put(response.URL, file);
            })
        );
    }

    uploadContactImage(studentProfileId: number, type: string, file: File): Observable<any> {
        const queryParams = CommonUtility.convertObjectToParams({
            studentProfileId: studentProfileId,
            contentType: file.type,
            contactType: type
        })
        return this.baseService.put(`${APIConstant.studentContactInfo}/image?${queryParams}`, null).pipe(
            mergeMap((response: any) => {
                return this.baseService.put(response.URL, file);
            })
        );
    }

    getContactById(studentProfileId: number): Observable<StudentContact> {
        return this.baseService.get<StudentContact>(`${APIConstant.studentContactInfo}/${studentProfileId}`);
    }

    addContact(data: Student): Observable<Student> {
        return this.baseService.post(`${APIConstant.studentContactInfo}`, data);
    }

    updateContact(id: number, data: Student): Observable<Student> {
        return this.baseService.put(`${APIConstant.studentContactInfo}/${id}`, data);
    }

    deleteContact(id: number): Observable<APIPagedResponse<boolean>> {
        return this.baseService.delete(`${APIConstant.studentContactInfo}/${id}`);
    }

    getContactDetailsReport(params: any, data: any): Observable<APIPagedResponse<Student>> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.post(`${APIConstant.studentViewAllReport}?${query}`, data);
    }

    drop(data: any) {
        return this.baseService.put(`${APIConstant.studentDrop}`, data);
    }

    getStudentMissingFields(): Observable<StudentField[]> {
        return this.baseService.get(`${APIConstant.studentMissingFields}`);
    }

    getStudentMissingData(params: any): Observable<APIPagedResponse<Student[]>> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.studentMissingIfoReport}?${query}`);
    }

    UpdateStudentMissingData(params: any, data: any) {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.put(`${APIConstant.studentMissingIfoReport}?${query}`, data);
    }

    getStudentReport(params: any, classroomId: any): Observable<APIPagedResponse<Student>> {
        const query = CommonUtility.convertObjectToParams(params);
        if (params.boardingType) {
            return this.baseService.post(`${APIConstant.studentViewAllReport}?${query}&boardingType=` + params.boardingType, classroomId);
        } else {
            return this.baseService.post(`${APIConstant.studentViewAllReport}?${query}`, classroomId);
        }
    }

    getProgressReport = (params: any): Observable<any> => {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.progressReport}?${query}`);
    }

    printProgressReport = (params: any): Observable<any> => {
        const query = CommonUtility.convertObjectToParams({
            module: 'ProgressReports',
            fileName: params.classroomId + '/' + params.examId + '/' + params.studentProfileId + '_' + params.examId
        });
        return this.baseService.get(`${APIConstant.documents}?${query}`);
    }

    importStudentData(params: any, data: any) {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.post(`${APIConstant.studentImport}?${query}`, data);
    }

    importStudentDataExcel(params: any, data: any) {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.post(`${APIConstant.studentImportExcel}?${query}`, data);
    }

    getImportedTasks(params: any) {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.importedTasks}?${query}`);
    }

    getStudentBirthdaysReport(data: any): Observable<Birthday[]> {
        const queryParams = CommonUtility.convertObjectToParams({
            ...data
        });
        return this.baseService.get<Birthday[]>(`${APIConstant.birthdays}?${queryParams}`);
    }

    getStudentDropOutData(params: any): Observable<StudentDropOutPaginate> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.studentDropOut}?${query}`);
    }

    getStudentStrengthData(params: any): Observable<StudentStrength[]> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.studentStrength}?${query}`);
    }

    getSchoolProfileType(): Observable<any> {
        return this.baseService.get(`${APIConstant.profileType}`);
    }

    getStudentImportTemplate(): Observable<any> {
        const query = CommonUtility.convertObjectToParams({
            module: 'General',
            fileName: 'StudentImportTemplate.csv'
        });
        return this.baseService.get(`${APIConstant.documents}?${query}`);
    }

    updateStudentHouseMapping(categoryMapping: any): Observable<any> {
        return this.baseService.put<any>(`${APIConstant.houseMappings}`, categoryMapping);
    }

    updateStudentFeeCategoryMapping(categoryMapping: any): Observable<any> {
        return this.baseService.put<any>(`${APIConstant.categoryMapping}`, categoryMapping);
    }

    getStudentFeeCategoryMappingByProfileId(studentProfileId: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({ studentProfileId });
        return this.baseService.get<any>(`${APIConstant.categoryMapping}?${params}`);
    }

    getStudentHouseMappingByProfileId(studentProfileId: number): Observable<any> {
        const params = CommonUtility.convertObjectToParams({ studentProfileId });
        return this.baseService.get<any>(`${APIConstant.houseMappings}?${params}`);
    }

    getStudentClasses(studentProfileId: number) {
        const params = CommonUtility.convertObjectToParams({ studentProfileId });
        return this.baseService.get<any>(`${APIConstant.studentClasses}?${params}`);
    }

    getExaminationByClassroom(params: { classroomId: number, academicYear: any }): Observable<Exam[]> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.exams}?${query}`);
    }

    getStudentMarksByProfileId(params: any): Observable<StudentMark> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.get(`${APIConstant.marksByStudent}?${query}`);
    }

    submitStudentCSV(params: any, data: any): Observable<APIPagedResponse<Student>> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.post(`${APIConstant.submitImportCSVData}?${query}`, data);
    }

    studentSearchFilter(data: any, params: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.post(`${APIConstant.searchStudent}?${query}`, data);
    }

    activateStudent(params: any): Observable<any> {
        const query = CommonUtility.convertObjectToParams(params);
        return this.baseService.put(`${APIConstant.activateStudent}?${query}`, {});
    }

    syncDataWithMagento(selectedSchoolCode: String)
    {
        return this.baseService.put(`${APIConstant.syncDataWithMagento}?selectedSchoolCode=`+selectedSchoolCode, {});
    }
}
